import axiosIns from "@/libs/axios";

export const departmentService = {
  fetchData: async (id) => {
    try {
      const response = await axiosIns.get(`/backoffice/department/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createData: async (data) => {
    try {
      const response = await axiosIns.post(`/backoffice/department/`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
