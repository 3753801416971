<template>
  <b-card :title="$t('evaluations.history')">
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <!-- <app-timeline-item>
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6>12 Invoices have been paid</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Invoices have been paid to the company.</p>
        <p class="mb-0">
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">invoice.pdf</span>
        </p>
      </app-timeline-item> -->
      <app-timeline-item
        v-for="evaluation in evaluationList"
        :key="evaluation._id"
        :variant="resolveEvaluationStatus(evaluation.status)"
      >
        <div
          v-if="evaluation.status === 'normal'"
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <div>
            <h6>{{ mappingEvaluationStatusText(evaluation.status) }}</h6>
          </div>
          <small class="text-muted">
            {{ evaluation.evaPeriod ? evaluation.evaPeriod.dateString : "-" }}
          </small>
        </div>
        <div
          v-else
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <div>
            <h6>{{ mappingEvaluationStatusText(evaluation.status) }}</h6>
            <span>
              <b>{{ evaluation.abnormalInformation.category.category }}</b>
              <ul>
                <li
                  v-for="reason in evaluation.abnormalInformation.reason"
                  :key="reason"
                >
                  {{ reason }}
                </li>
              </ul>
            </span>
            <span>
              <b>รายละเอียดเพิ่มเติม</b>
              <ul>
                <li>
                  {{ evaluation.abnormalInformation.detail }}
                </li>
              </ul>
            </span>
            <span>
              <b>เอกสารหลักฐาน</b>
              <ul>
                <li
                  v-for="attachment in evaluation.abnormalInformation
                    .attachment"
                  :key="attachment._id"
                >
                  <a
                    :href="`${`${$apiURL}/files/${attachment.fileName}`}`"
                    target="_blank"
                    >{{ attachment.fileName }}</a
                  >
                </li>
                <li
                  v-if="evaluation.abnormalInformation.attachment.length === 0"
                >
                  ไม่มีเอกสาร
                </li>
              </ul>
            </span>
          </div>
          <small class="text-muted">
            {{ evaluation.evaPeriod ? evaluation.evaPeriod.dateString : "-" }}
          </small>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {
  resolveEvaluationStatus,
  dateTimeToStr,
  mappingEvaluationStatusText,
} from "@/libs/helper";
export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    evaluationList: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      resolveEvaluationStatus,
      dateTimeToStr,
      mappingEvaluationStatusText,
    };
  },
};
</script>

<style></style>
