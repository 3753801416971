import axiosIns from "@/libs/axios";

export const companyService = {
  fetchData: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/company/${queryParams ? "?" + queryParams : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createData: async (data) => {
    try {
      const response = await axiosIns.post("/backoffice/company/", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getCompany: async (id) => {
    try {
      const response = await axiosIns.get(`/backoffice/company/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getStatic: async (id) => {
    try {
      const response = await axiosIns.get(`/backoffice/company/${id}/static`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateData: async (data, id) => {
    try {
      const response = await axiosIns.put(`/backoffice/company/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  //ADMIN
  getCompanyAdmin: async () => {
    try {
      const response = await axiosIns.get(`/backoffice/company/admin/home`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
