import axiosIns from "@/libs/axios";

export const employeeService = {
  fetchData: async (queryParams) => {
    try {
      const response = await axiosIns.get(
        `/backoffice/employee/get?${queryParams}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createData: async (data) => {
    try {
      const response = await axiosIns.post("/backoffice/employee/create", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getData: async (id) => {
    try {
      const response = await axiosIns.get(`/backoffice/employee/view/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateData: async (data, id) => {
    try {
      const response = await axiosIns.put(
        `/backoffice/employee/update/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDataNationalID: async (id) => {
    try {
      const response = await axiosIns.get(`/backoffice/employee/${id}/data`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
