<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <b-card no-body class="mb-0">
        <!-- <b-card-header class="pt-3 d-flex justify-content-center">
      <h2>{{ $t('add_employee') }}</h2>
    </b-card-header> -->
        <b-row class="m-2 pt-2">
          <b-col cols="12" md="3">
            <div class="d-flex flex-column align-items-center">
              <b-avatar
                rounded="bottom"
                variant="light-dark"
                size="168"
                :src="previewAvatar ? previewAvatar : userData.avatar"
              />
              <b-button
                variant="primary"
                type="submit"
                class="mt-1"
                @click="$refs.refInputEl.click()"
              >
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="inputImage"
                />
                {{ $t("upload_image") }}
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <!-- National ID -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('national_id')"
                  rules="required|integer|national-id"
                >
                  <b-form-group
                    :label="$t('national_id')"
                    label-for="required|integer|national-id"
                  >
                    <b-input-group>
                      <b-form-input
                        id="national-id"
                        v-model="userData.nationalID"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="searchNationalID(validationContext.errors[0])"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
                <!-- Company -->
                <b-form-group
                  :label="$t('company')"
                  label-for="company"
                  v-if="currentRole.type === 'admin'"
                >
                  <v-select
                    v-model="userData.company"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="companyOptions"
                    :reduce="(val) => val._id"
                    :getOptionLabel="(role) => role.name"
                    :clearable="false"
                    input-id="department"
                  />
                  <!-- <vue-autosuggest
                  v-model="userData.company"
                  :suggestions="filteredCompany"
                  @selected="onSelectedCompany"
                  :limit="10"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: 'form-control',
                  }"
                  @input="onInputChange"
                >
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item">
                      {{ suggestion.item.name }}
                    </span>
                  </template>
                </vue-autosuggest> -->
                </b-form-group>
                <!-- <validation-provider
                #default="validationContext"
                name="Company"
                rules="required"
                v-if="currentRole.type === 'admin'"
              >
                
              </validation-provider> -->

                <!-- Full Name -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('first_name')"
                  rules="required"
                >
                  <b-form-group :label="$t('first_name')" label-for="fist-name">
                    <b-form-input
                      id="first-name"
                      v-model="userData.firstName"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Last Name -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('last_name')"
                  rules="required"
                >
                  <b-form-group :label="$t('last_name')" label-for="last-name">
                    <b-form-input
                      id="last-name"
                      v-model="userData.lastName"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- User Role -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('department')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('department')"
                    label-for="department"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="userData.department"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptions"
                      :reduce="(val) => val._id"
                      :getOptionLabel="(role) => role.name"
                      :clearable="false"
                      input-id="department"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <div class="form-group">
                  <label for="wildcard">{{ $t("upload_national_id") }}</label>
                  <b-form-file
                    v-model="uploadNationalFile"
                    :placeholder="
                      userData.nationalFile
                        ? userData.nationalFile.fileName
                        : 'อัพโหลดไฟล์'
                    "
                    drop-placeholder="Drop file here..."
                    no-drop
                  />
                </div>

                <div class="form-group">
                  <label for="wildcard">{{ $t("upload_registration") }}</label>
                  <b-form-file
                    v-model="uploadRegistrationFile"
                    :placeholder="
                      userData.registrationFile
                        ? userData.registrationFile.fileName
                        : 'อัพโหลดไฟล์'
                    "
                    drop-placeholder="Drop file here..."
                    no-drop
                  />
                </div>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button variant="primary" class="mr-2" type="submit">
                    {{ id ? $t("edit_employee") : $t("add_employee") }}
                  </b-button>
                  <b-button type="button" variant="outline-secondary">
                    {{ $t("cancel") }}
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormGroup,
  BAvatar,
  BCardHeader,
  BForm,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import UserViewUserTimelineCard from "../view/UserViewUserTimelineCard.vue";
import { onMounted, ref } from "@vue/composition-api";
import { required, integer, nationalID } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import router from "@/router";
import { employeeService } from "@/services/employee";
import { uploadService } from "@/services/upload";
import { VueAutosuggest } from "vue-autosuggest";
import { decodeToken } from "@/@core/utils/decodeJwt";
import { companyService } from "@/services/company";
import { departmentService } from "@/services/department";
import { constructUrlImage } from "@/libs/helper";
export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BAvatar,
    BForm,
    BFormFile,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    UserViewUserTimelineCard,
    VueAutosuggest,
    vSelect,
    BOverlay,
  },
  setup() {
    const id = router.currentRoute.params.id;

    const toast = useToast();

    const currentRole = decodeToken();

    const filteredCompany = ref([
      {
        data: [],
      },
    ]);
    const blankUserData = {
      firstName: "",
      lastName: "",
      nationalID: "",
      department: "",
      avatar: "",
      registrationFile: null,
      nationalFile: null,
    };

    const refInputEl = ref(null);
    const previewAvatar = ref(null);
    const loading = ref(false);
    const avatarFile = ref(null);
    const uploadNationalFile = ref(null);
    const uploadRegistrationFile = ref(null);

    const userData = ref({ ...blankUserData });

    const resetUserData = () => {
      userData.value = { ...blankUserData };
    };

    const companyOptions = ref([]);
    const roleOptions = ref([]);
    const foundUser = ref(false);
    const searchNationalID = async (error) => {
      if (!error) {
        try {
          const employee = await employeeService.getDataNationalID(
            userData.value.nationalID
          );

          if (employee.data) {
            foundUser.value = true;
            userData.value.firstName = employee.data.firstName;
            userData.value.lastName = employee.data.lastName;
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    onMounted(() => {
      if (currentRole.type === "admin") {
        companyService
          .fetchData()
          .then((res) => {
            companyOptions.value = res.data;
            filteredCompany.value[0].data = res.data;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        departmentService
          .fetchData(currentRole.id)
          .then((res) => {
            roleOptions.value = res.data;
            if (!id) {
              // console.log("comin", userData.value);
              userData.value.department = res.data[0]._id;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    const onInputChange = (text) => {
      const filter = {
        search: text,
      };
      const queryParams = new URLSearchParams(filter).toString();
      companyService
        .fetchData(queryParams)
        .then((res) => {
          filteredCompany.value[0].data = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const inputImage = (e) => {
      avatarFile.value = e.target.files[0];
      previewAvatar.value = URL.createObjectURL(avatarFile.value);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetUserData);

    const onSelectedCompany = (option) => {
      userData.value.company = option.item._id;

      departmentService
        .fetchData(option.item._id)
        .then((res) => {
          roleOptions.value = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (id) {
      loading.value = true;
      employeeService
        .getData(id)
        .then((response) => {
          loading.value = false;
          if (response.status) {
            userData.value = response.data;
            const imageURL = constructUrlImage(response.data.avatar);
            userData.value.avatar = imageURL;
          }
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetch company",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }

    const onSubmit = async () => {
      loading.value = true;
      const updateData = { ...userData.value };

      delete updateData.registrationFile;
      delete updateData.nationalFile;
      delete updateData.avatar;

      try {
        //check file if file not null then call api to store file and get file to userData
        if (uploadNationalFile.value) {
          const formData = new FormData();
          formData.append(
            "file",
            uploadNationalFile.value,
            uploadNationalFile.value.name
          );

          formData.append("fileType", "nationalID");

          const respNationalFile = await uploadService.uploadFile(formData);
          updateData.nationalFile = respNationalFile.data._id;
        }

        if (uploadRegistrationFile.value) {
          const formDataRegis = new FormData();
          formDataRegis.append(
            "file",
            uploadRegistrationFile.value,
            uploadRegistrationFile.value.name
          );

          formDataRegis.append("fileType", "registration");

          const respRegistrationFile = await uploadService.uploadFile(
            formDataRegis
          );
          updateData.registrationFile = respRegistrationFile.data._id;
        }

        if (avatarFile.value) {
          const formDataLogo = new FormData();
          formDataLogo.append("file", avatarFile.value, avatarFile.value.name);
          formDataLogo.append("fileType", "avatar");
          const respAvatarFile = await uploadService.uploadFile(formDataLogo);
          updateData.avatar = respAvatarFile.data._id;
        }

        let response = null;

        if (id) {
          response = await employeeService.updateData(updateData, id);
        } else {
          response = await employeeService.createData(updateData);
        }

        if (response.status) {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Manage employee success",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          //Router push to company list
          router.push({ name: "employee" }).catch(() => {});
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "Manage employee failed",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    };

    return {
      userData,
      roleOptions,
      required,
      nationalID,
      integer,
      loading,
      refInputEl,

      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      inputImage,

      avatarFile,
      uploadNationalFile,
      uploadRegistrationFile,
      previewAvatar,

      currentRole,
      filteredCompany,
      onInputChange,
      onSelectedCompany,

      searchNationalID,
      companyOptions,

      id,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
